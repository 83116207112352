import { createApp } from '@vue/compat'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import mitt from 'mitt'
import Toast, { POSITION } from 'vue-toastification'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

const emitter = mitt()
const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(BootstrapVue)
app.use(IconsPlugin)
app.use(Toast, { timeout: 30000, position: POSITION.BOTTOM_RIGHT })

app.config.globalProperties.emitter = emitter

app.mount('#app')